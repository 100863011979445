import { WidgetTypes } from "@superblocksteam/shared";
import { WidgetProps } from "legacy/widgets";
import { ApiDtoWithPb } from "store/slices/apisV2/slice";

export type DiscardedEdit = {
  propertyName: string;
  reason: string;
  propertyValue: unknown;
};

export type BaseComponentAction = {
  id: string;
  componentName: string;
};

export type SetAction = BaseComponentAction & {
  action: "set";
  property: string;
  value: unknown;
};

export type EventDetails = {
  id: string;
  eventType: RESPONSE_SCHEMA_TYPE;
  page: string;
  componentType?: string;
  componentName?: string;
  updateType?: string;
  apiName?: string;
  stepName?: string;
  data: Record<string, unknown> | Record<string, unknown>[];
};

type ShowAlertTrigger = {
  type: "showAlert";
  message: string;
  style: "info" | "warning" | "error" | "success" | "default";
  alertDuration?: number;
  alertPosition?:
    | "bottomRight"
    | "bottomLeft"
    | "topRight"
    | "topLeft"
    | "top"
    | "bottom";
  [key: string]: unknown;
};

type RunJsTrigger = {
  type: "runJs";
  code: string;
  [key: string]: unknown;
};

type ExecuteApiTrigger = {
  type: "executeApi";
  apiNames: string[];
  [key: string]: unknown;
};

type CancelApiTrigger = {
  type: "cancelApi";
  apiNames: string[];
  [key: string]: unknown;
};

type ControlModalTrigger = {
  type: "controlModal";
  name: string;
  direction: "open" | "close";
  [key: string]: unknown;
};

type ControlSlideoutTrigger = {
  type: "controlSlideout";
  name: string;
  direction: "open" | "close";
  [key: string]: unknown;
};

type NavigateToTrigger = {
  type: "navigateTo";
  url: string;
  newWindow: boolean;
  replaceHistory: boolean;
  [key: string]: unknown;
};

type ResetStateVarTrigger = {
  type: "resetStateVar";
  state: {
    name: string;
    scope: string;
  };
  [key: string]: unknown;
};

type SetStateVarTrigger = {
  type: "setStateVar";
  state: {
    name: string;
    scope: string;
  };
  value: string;
  [key: string]: unknown;
};

type ControlTimerTrigger = {
  type: "controlTimer";
  command: "start" | "stop" | "toggle";
  state: {
    name: string;
    scope: string;
  };
  name?: string;
  [key: string]: unknown;
};

type TriggerEventTrigger = {
  type: "triggerEvent";
  event: {
    name: string;
    scope: string;
  };
  eventPayload?: Record<string, any>;
  [key: string]: unknown;
};

type SetProfileTrigger = {
  type: "setProfile";
  profileAction: "set" | "unset";
  profileId: string;
  [key: string]: unknown;
};

type SetComponentPropertyTrigger = {
  type: "setComponentProperty";
  component: string;
  propertyName: string;
  propertyValue: string;
  [key: string]: unknown;
};

type ResetComponentPropertyTrigger = {
  type: "resetComponent";
  component: string;
  propertyName: string;
  [key: string]: unknown;
};

type NavigateToRouteTrigger = {
  type: "navigateToRoute";
  routePathDescriptor: string;
  [key: string]: unknown;
};

export type AddEventActionValue =
  | SetStateVarTrigger
  | ControlTimerTrigger
  | TriggerEventTrigger
  | SetProfileTrigger
  | SetComponentPropertyTrigger
  | ResetComponentPropertyTrigger
  | NavigateToRouteTrigger
  | ResetStateVarTrigger
  | RunJsTrigger
  | ExecuteApiTrigger
  | CancelApiTrigger
  | ControlModalTrigger
  | ControlSlideoutTrigger
  | NavigateToTrigger
  | ShowAlertTrigger;

export type AddEventAction = BaseComponentAction & {
  action: "add";
  property: string;
  value: AddEventActionValue;
};

export type RemoveEventAction = BaseComponentAction & {
  action: "remove";
  property: string;
  value: {
    id: string;
    type?: "remove";
  };
};
export type ResetPropertyAction = BaseComponentAction & {
  action: "reset";
  property: string;
};

export type RemovePropertyAction = BaseComponentAction & {
  action: "remove";
  property: string;
};

export type ComponentCreateAction = BaseComponentAction & {
  action: "createComponent";
  componentType: string;
  componentName: string;
};

export type ComponentRemoveAction = BaseComponentAction & {
  action: "removeComponent";
  componentType: string;
  componentName: string;
};

/* table column actions */
export type SetColumnAction = SetAction & {
  table_column: string;
};

export type RemoveColumnPropertyAction = RemovePropertyAction & {
  table_column: string;
};

export type RemoveColumnEventAction = RemoveEventAction & {
  table_column: string;
};

export type AddColumnEventAction = AddEventAction & {
  table_column: string;
};

export type RemoveColumnAction = BaseComponentAction & {
  action: "remove";
  table_column: string;
  property: undefined;
  value: undefined;
};

export type ColumnAction =
  | SetColumnAction
  | RemoveColumnPropertyAction
  | RemoveColumnEventAction
  | AddColumnEventAction
  | RemoveColumnAction;

/* tab actions */

export type SetTabAction = SetAction & {
  tabs_tab: string;
};

export type RemoveTabAction = BaseComponentAction & {
  action: "remove";
  tabs_tab: string;
  property?: undefined;
  value?: undefined;
};

export type RemoveTabPropertyAction = RemovePropertyAction & {
  tabs_tab: string;
};

export type TabAction =
  | RemoveTabAction
  | RemoveTabPropertyAction
  | SetTabAction;

/* section column actions */
export type SetSectionColumnAction = SetAction & {
  section_column: string;
};

export type RemoveSectionColumnAction = BaseComponentAction & {
  action: "remove";
  property?: undefined;
  value?: undefined;
};

export type SectionColumnAction =
  | SetSectionColumnAction
  | RemoveSectionColumnAction;

/* menu item actions */
export type SetMenuItemAction = SetAction & {
  menu_item: string;
};

export type ResetMenuItemAction = ResetPropertyAction & {
  menu_item: string;
};

export type RemoveMenuItemEventHandlerAction = RemoveEventAction & {
  menu_item: string;
};

export type AddMenuItemPropertyAction = AddEventAction & {
  menu_item: string;
};

export type RemoveMenuItemAction = BaseComponentAction & {
  action: "remove";
  menu_item: string;
  property: undefined;
  value: undefined;
};

export type MenuItemAction =
  | SetMenuItemAction
  | ResetMenuItemAction
  | RemoveMenuItemEventHandlerAction
  | AddMenuItemPropertyAction
  | RemoveMenuItemAction;

/* kv item actions */

export type SetKvPropertyAction = SetAction & {
  key_value_property: string;
};

export type AddKvEventAction = AddEventAction & {
  key_value_property: string;
};

export type RemoveKvEventAction = RemoveEventAction & {
  key_value_property: string;
};

export type ResetKvPropertyAction = ResetPropertyAction & {
  key_value_property: string;
};

export type RemoveKvItemAction = RemovePropertyAction & {
  key_value_property: string;
};

export type KvAction =
  | RemoveKvItemAction
  | SetKvPropertyAction
  | AddKvEventAction
  | RemoveKvEventAction
  | ResetKvPropertyAction;

/* all actions */
export type ComponentEditAction =
  | SetAction
  | AddEventAction
  | RemoveEventAction
  | ResetPropertyAction
  | RemovePropertyAction
  | ColumnAction
  | TabAction
  | MenuItemAction
  | KvAction
  | SectionColumnAction;

export type ClarkComponentAction =
  | ComponentEditAction
  | ComponentCreateAction
  | ComponentRemoveAction;

export type ClarkAction = ClarkComponentAction | ClarkApiAction;

export type ClarkApiAction =
  | ClarkCreateApiAction
  | ClarkEditApiAction
  | ClarkScaffoldApiAction
  | ClarkCompleteApiAction;

export type ClarkCreateApiAction = {
  id: string;
  action: "createApi";
  apiName: string;
};

export type ClarkScaffoldApiAction = {
  id: string;
  action: "scaffoldApi";
  apiName: string;
  apiSpec: Record<string, unknown>[]; // APIv2 Spec
};

export type ClarkEditApiAction = {
  id: string;
  action: "editApi";
  apiName: string;
  stepName: string;
  stepEdit: {
    action: "set";
    property: string;
    value: unknown;
  };
};

export type ClarkCompleteApiAction = {
  id: string;
  action: "completeApi";
  apiName: string;
};

export type ClarkContextIntegration = {
  name: string;
  type: string;
  metadata: Record<string, unknown>;
};

export type SortedProperties = {
  widgetId?: string;
  eventListeners: Record<string, any>;
  staticProperties: Record<string, any>;
  dynamicProperties: Record<string, any>;
  immutableState: Record<string, any>;
  mutableState: Record<string, any>;
  removableProperties?: string[];
  resettableProperties?: string[];
};

export type MenuMetadata = {
  indexMap: Record<number, number>;
  menuItemChanges: Record<
    number,
    | {
        status: "updated";
        updates: Record<string, any>;
      }
    | {
        status: "added";
        updates: Record<string, any>;
      }
    | {
        status: "removed";
      }
    | null
  >;
};

export type EditMetadata = {
  // for tabs and menus, we need to maintain a mapping of ids to indices
  // clark AI operates on index, so if items are added or removed, we need to update the index
  idsToIndices: Record<string, number>;
};

export const PLANNER_ACTION_TYPE =
  "com.superblocks.ai.clark.subsystem.unicomedt.layer.planner";
export const MULTI_COMPONENT_ACTION_TYPE =
  "com.superblocks.ai.clark.subsystem.multicomedt";
export const SINGLE_EDIT_ACTION_TYPE =
  "com.superblocks.ai.clark.subsystem.unicomedt.layer.value";
export const COMPONENT_UPDATE_REQUEST_TYPE =
  "com.superblocks.ai.clark.subsystem.unicomedt.layer.planner.component_update_request";
export const COMPONENT_UPDATE_RESPONSE_TYPE =
  "com.superblocks.ai.clark.subsystem.unicomedt.layer.planner.component_update_response";
export const API_SCAFFOLDING_ACTION_TYPE =
  "com.superblocks.ai.clark.subsystem.api.layer.scaffolding";
export const API_EDIT_TYPE =
  "com.superblocks.ai.clark.subsystem.api.layer.integration";
export const SDLC_IMPLEMENT_TYPE = "com.superblocks.ai.clark.sdlc.implement";
export const SDLC_TEST_TYPE = "com.superblocks.ai.clark.sdlc.test";
export const SDLC_TEST_DEBUG_TYPE = "com.superblocks.ai.clark.sdlc.debug";
export const SDLC_TEST_RESPONSE_TYPE =
  "com.superblocks.ai.clark.sdlc.test.response";

export const API_COMPLETED_TYPE =
  "com.superblocks.ai.clark.subsystem.api.layer.finish";

export enum RESPONSE_SCHEMA_TYPE {
  MULTI_COMPONENT_EVENT = "MULTI_COMPONENT_EVENT",
  PROPERTIES_EVENT = "PROPERTIES_EVENT",
  SINGLE_EDIT_EVENT = "SINGLE_EDIT_EVENT",
  REQUEST_COMPONENT_UPDATE = "REQUEST_COMPONENT_UPDATE",
  API_SCAFFOLDING_EVENT = "API_SCAFFOLDING_EVENT",
  API_COMPLETED_EVENT = "API_COMPLETED_EVENT",
  SDLC_IMPLEMENT_EVENT = "SDLC_IMPLEMENT_EVENT",
  SDLC_TEST_EVENT = "SDLC_TEST_EVENT",
  SDLC_TEST_DEBUG_EVENT = "SDLC_TEST_DEBUG_EVENT",
}

export const RESPONSE_SCHEMA_TYPE_MAP: Record<string, RESPONSE_SCHEMA_TYPE> = {
  [MULTI_COMPONENT_ACTION_TYPE]: RESPONSE_SCHEMA_TYPE.MULTI_COMPONENT_EVENT,
  [PLANNER_ACTION_TYPE]: RESPONSE_SCHEMA_TYPE.PROPERTIES_EVENT,
  [SINGLE_EDIT_ACTION_TYPE]: RESPONSE_SCHEMA_TYPE.SINGLE_EDIT_EVENT,
  [COMPONENT_UPDATE_REQUEST_TYPE]:
    RESPONSE_SCHEMA_TYPE.REQUEST_COMPONENT_UPDATE,
  [API_SCAFFOLDING_ACTION_TYPE]: RESPONSE_SCHEMA_TYPE.API_SCAFFOLDING_EVENT,
  [API_COMPLETED_TYPE]: RESPONSE_SCHEMA_TYPE.API_COMPLETED_EVENT,
  [SDLC_IMPLEMENT_TYPE]: RESPONSE_SCHEMA_TYPE.SDLC_IMPLEMENT_EVENT,
  [SDLC_TEST_TYPE]: RESPONSE_SCHEMA_TYPE.SDLC_TEST_EVENT,
  [SDLC_TEST_DEBUG_TYPE]: RESPONSE_SCHEMA_TYPE.SDLC_TEST_DEBUG_EVENT,
};

export const SDLC_EVENT_TYPES = [
  SDLC_IMPLEMENT_TYPE,
  SDLC_TEST_TYPE,
  SDLC_TEST_DEBUG_TYPE,
];

export type RawClarkEvent = {
  id: string;
  type: string;
  source: string;
  data: {
    update_type?: string;
    summary?: string;
    suggestion?: string;
    properties?: string[];
    property?: any;
    action?: string;
    value?: any;
    // keys for component-specific edit actions
    table_column?: string;
    key_value_property?: string;
    tabs_tab?: string;
    menu_item?: string;
    // error cases
    message?: string;
    type?: string;
    error?: {
      property: string;
      message: string;
    };
  };
};

export type ResultHistoryItem = {
  widgetId: string;
  widgetName: string | undefined;
  dataTreeChanges: Record<string, Record<string, unknown> | null>;
  changedKeys: Record<string, string[]>;
  prompt: string;
  status: "accept" | "discard";
  addedWidgetIds: string[];
  metadataByWidgetId: Record<string, EditMetadata | undefined>;
  renamesByWidgetId: Record<string, string>;
  apiChanges: Record<string, Record<string, unknown> | null>;
};

export type SldcMessageTestStatus =
  | "requestToVerify"
  | "reportIssues"
  | "issuesReported"
  | "noIssuesReported";

export type SldcMessage = {
  id: string;
  message: string;
} & (
  | { type: "update" }
  | { type: "test"; status: SldcMessageTestStatus; answer?: string }
);

export type SldcMessages = Array<SldcMessage>;

export type ClarkActionFuntions = {
  createComponentFn: (args: {
    parentWidgetId: string;
    widgetType: WidgetTypes;
    newWidgetId: string;
    initialProps: Partial<WidgetProps>;
  }) => void;
  updateComponentFn: (args: {
    widgetId: string;
    widgetUpdates: Partial<WidgetProps>;
  }) => void;
  deleteComponentFn: (args: { widgetId: string }) => void;
  deleteComponentPropertiesFn: (args: {
    widgetId: string;
    propertyPaths: string[];
  }) => void;
  addSectionColumnFn: (args: {
    sectionWidgetId: string;
    columnProperties?: Record<string, any>;
  }) => void;
  renameEntityFn: (args: {
    entityId: string;
    oldName: string;
    newName: string;
  }) => void;
  createApiFn: (api: ApiDtoWithPb) => void;
  updateApiFn: (updatedApi: ApiDtoWithPb) => void;
};

export type ClarkWebSocketConnectionClosedReason =
  | "manualDisconnect"
  | "gracefulDisconnect"
  | "serverError";

export enum ClarkFocusedEntityType {
  WIDGET = "WIDGET",
  API_BLOCK = "API_BLOCK",
  // todo later
  // API = "API",
  // STATE_VAR = "STATE_VAR",
  // TIMER = "TIMER",
  // EVENT = "EVENT",
}
