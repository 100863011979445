import { set, get } from "lodash";
import { put, select } from "redux-saga/effects";
import { updateWidgetProperties } from "legacy/actions/controlActions";
import { WidgetType } from "legacy/constants/WidgetConstants";
import { DataTreeWidget } from "legacy/entities/DataTree/dataTreeFactory";
import { getItemPropertyPaneConfig } from "legacy/pages/Editor/PropertyPane/ItemPropertyPaneConfig";
import {
  FlattenedWidgetProps,
  CanvasWidgetsReduxState,
} from "legacy/reducers/entityReducers/canvasWidgetsReducer";
import { WidgetMetadata } from "legacy/reducers/entityReducers/metaReducer";
import { APP_MODE } from "legacy/reducers/types";
import { getAppMode } from "legacy/selectors/applicationSelectors";
import { getDataTreeWidgetsById } from "legacy/selectors/dataTreeSelectors";
import { getFlattenedCanvasWidgets } from "legacy/selectors/editorSelectors";
import { getWidget, getWidgets } from "legacy/selectors/entitiesSelector";
import { getDynamicLayoutWidgets } from "legacy/selectors/layoutSelectors";
import { getWidgetsMeta } from "legacy/selectors/sagaSelectors";
import { selectGeneratedTheme } from "legacy/selectors/themeSelectors";
import { SB_CUSTOM_TEXT_STYLE } from "legacy/themes/typographyConstants";
import { mergeUpdatesWithBindingsOrTriggers } from "legacy/utils/DynamicBindingUtils";
import WidgetFactory, { WidgetActionResponse } from "legacy/widgets/Factory";
import { AllFlags, Flag, selectFlags } from "store/slices/featureFlags";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { updateAiChanges, setAiChanges } from "../slice";

export function* updateAiChangesSaga(
  action: ReturnType<typeof updateAiChanges>,
) {
  const aiState: ReturnType<typeof selectAiState> = yield select(selectAiState);
  const {
    changedKeys,
    dataTreeChanges,
    renamesByWidgetId,
    discardedEdits,
    focusedEntityId,
  } = aiState;
  const widgetId =
    ((action.payload as any)?.properties?.widgetId as string) ??
    focusedEntityId;

  if ("rename" in action.payload) {
    yield put(
      setAiChanges({
        changedKeys: changedKeys || {},
        dataTreeChanges: dataTreeChanges || {},
        renamesByWidgetId: renamesByWidgetId ?? {},
        discardedEdits: discardedEdits || {},
      }),
    );
  } else {
    const { updates, properties } = action.payload;
    const id = properties.widgetId as string;

    const featureFlags: Partial<AllFlags> = yield select(selectFlags);
    const changesWithBindings = mergeUpdatesWithBindingsOrTriggers(
      properties,
      getItemPropertyPaneConfig(properties.type as WidgetType),
      updates,
      featureFlags[Flag.ENABLE_DEEP_BINDINGS_PATHS],
    );

    const newDataTreeChanges = dataTreeChanges
      ? fastClone(dataTreeChanges)
      : {};

    const newDataTreeChangesForSelectedWidget = fastClone(
      dataTreeChanges?.[widgetId] || {},
    );

    const newChangedKeys = changedKeys ? fastClone(changedKeys[widgetId]) : [];
    Object.entries(changesWithBindings).forEach(([key, value]) => {
      set(newDataTreeChangesForSelectedWidget, key, value);
      if (!newChangedKeys.includes(key)) {
        newChangedKeys.push(key);
      }
    });
    const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
      getWidget(state, widgetId ?? ""),
    );

    // Filter out undefined text style properties from newChangedKeys
    // to ensure we don't show inline text style properties that are not in aiEdits
    // this is for when a user manually changes to a variant from a custom variant that
    // was previously set by the AI
    let hasCustomTextStyleVariantUpdate = false;
    let hasTextStyleUpdate = false;
    for (const [key, value] of Object.entries(updates)) {
      if (key.includes("textStyle.variant") && value === SB_CUSTOM_TEXT_STYLE) {
        hasCustomTextStyleVariantUpdate = true;
      }
      if (key.includes("textStyle.variant") && value !== undefined) {
        hasTextStyleUpdate = true;
      }
    }

    const filteredNewChangedKeys = newChangedKeys.filter(
      (dottedPropertyPath: string) => {
        const isANonVariantTextStyleProperty =
          dottedPropertyPath.includes("textStyle.") &&
          !dottedPropertyPath.includes("textStyle.variant");

        if (
          hasTextStyleUpdate &&
          isANonVariantTextStyleProperty &&
          updates &&
          (get(updates, dottedPropertyPath) === undefined ||
            hasCustomTextStyleVariantUpdate)
        ) {
          return false;
        }
        return true;
      },
    );

    const widgetForUpdates = existingWidget ?? dataTreeChanges?.[id];

    const WidgetClass = WidgetFactory.widgetClasses.get(widgetForUpdates.type);
    if (WidgetClass && WidgetClass.applyActionHook) {
      const canvasWidgets: ReturnType<typeof getWidgets> =
        yield select(getWidgets);
      const flags: ReturnType<typeof selectFlags> = yield select(selectFlags);
      const appMode: APP_MODE = yield select(getAppMode) ?? APP_MODE.PUBLISHED;
      const theme: ReturnType<typeof selectGeneratedTheme> =
        yield select(selectGeneratedTheme);
      const dynamicWidgetLayout: ReturnType<typeof getDynamicLayoutWidgets> =
        yield select(getDynamicLayoutWidgets);
      const widgetsRuntime: CanvasWidgetsReduxState = yield select(
        getFlattenedCanvasWidgets,
      );
      const widgetMetaProps: WidgetMetadata = yield select(getWidgetsMeta);
      const evaluatedWidgets: Record<string, DataTreeWidget> = yield select(
        getDataTreeWidgetsById,
      );

      const hookParams = {
        originalWidgetValues: {},
        flags,
        appMode,
        theme,
        widgetsRuntime,
        widgetMetaProps,
        evaluatedWidgets,
        dynamicWidgetLayout: dynamicWidgetLayout,
        previousAiWidgets: newDataTreeChanges as unknown as Record<
          string,
          DataTreeWidget
        >,
      };
      const actionHookResult: WidgetActionResponse<FlattenedWidgetProps> =
        WidgetClass.applyActionHook({
          ...hookParams,
          widgets: {
            ...canvasWidgets,
            [id]: widgetForUpdates,
          },
          widgetId: id,
          action: {
            payload: {
              widgetId: id,
              // todo: ideally this is only the net new changes from clark actions, not all widget props
              updates,
            },
            type: updateWidgetProperties.type,
          },
        });

      for (const update of actionHookResult.widgetUpdates) {
        newDataTreeChanges[id] = {
          ...newDataTreeChanges[id],
          ...update.widget,
        };
      }

      // todo: handle meta updates
    }

    yield put(
      setAiChanges({
        changedKeys: {
          ...changedKeys,
          [widgetId]: filteredNewChangedKeys,
        },
        dataTreeChanges: {
          ...newDataTreeChanges,
          [widgetId]: newDataTreeChangesForSelectedWidget,
        },
        renamesByWidgetId,
        discardedEdits: discardedEdits ?? {},
      }),
    );
  }
}
