import { put, select } from "redux-saga/effects";
import { getWidget } from "legacy/selectors/entitiesSelector";
import { fastClone } from "utils/clone";
import { selectAiState } from "../selectors";
import { setAiChanges } from "../slice";
import { removeChildInAiChanges } from "../slice";

export function* removeChildInAiChangesSaga(
  action: ReturnType<typeof removeChildInAiChanges>,
): Generator<any, void, any> {
  // If it's a new child from ai:
  // 1. We need to delete from the dataTreeChanges
  // 2. We need to remove from dataTreeChanges.children
  // If it's an existing child:
  // 1. We need to ensure it's reflected in the dataTreeChanges.children for the selected ai widget so gets removed

  const {
    dataTreeChanges,
    changedKeys,
    discardedEdits,
    focusedEntityId,
  }: ReturnType<typeof selectAiState> = yield select(selectAiState);

  const { widgetId } = action.payload;
  if (!focusedEntityId) {
    return;
  }

  const existingWidget: ReturnType<typeof getWidget> = yield select((state) =>
    getWidget(state, focusedEntityId ?? ""),
  );

  const newDataTreeChanges = fastClone(dataTreeChanges || {});
  newDataTreeChanges[widgetId] = null;

  const newDataTreeChangesForSelectedWidget = fastClone(
    newDataTreeChanges[focusedEntityId] || {},
  );

  if (newDataTreeChangesForSelectedWidget.children) {
    newDataTreeChangesForSelectedWidget.children = (
      newDataTreeChangesForSelectedWidget.children as string[]
    ).filter((child: string) => child !== widgetId);
  } else {
    // currently children is not set, so we need to set it to the current value from redux
    // and then remove the widgetId from it
    newDataTreeChangesForSelectedWidget.children = (
      existingWidget.children as string[]
    ).filter((child: string) => child !== widgetId);
  }

  // TODO: AI team: Any hooks need to be run here? Seems like the delete hooks can be run on accept and that's likely fine. This is a delete, so not sure it needs update hooks at all.

  yield put(
    setAiChanges({
      changedKeys: changedKeys || {},
      dataTreeChanges: {
        ...newDataTreeChanges,
        [focusedEntityId]: newDataTreeChangesForSelectedWidget,
      },
      discardedEdits: discardedEdits || {},
    }),
  );
}
