import { takeLatest, ActionPattern } from "redux-saga/effects";
import { selectWidgets } from "legacy/actions/widgetActions";
import {
  processAiActions,
  processAiProperties,
  updateAiChanges,
  updateAiDynamicProperties,
  removeChildInAiChanges,
  restoreDeletedChildInAiChanges,
  doSetupBeforeClarkRequest,
  clearAiChanges,
} from "../slice";
import { clearAiChangesSaga } from "./clearAiChanges";
import { doSetupBeforeClarkRequestSaga } from "./doSetupBeforeClarkRequestSaga";
import { processAiActionsSaga } from "./processAiActions";
import { processAiPropertiesSaga } from "./processAiProperties";
import { removeChildInAiChangesSaga } from "./removeChildInAiChangesSaga";
import { restoreDeletedChildInAiChangesSaga } from "./restoreDeletedChildInAiChanges";
import { setFocusedEntitySaga } from "./setFocusedEntitySaga";
import { updateAiChangesSaga } from "./updateAiChanges";
import { updateAiDynamicPropertiesSaga } from "./updateDynamicProperties";

export function* aiSaga() {
  yield takeLatest(selectWidgets.type, setFocusedEntitySaga);
  yield takeLatest(processAiActions.type, processAiActionsSaga);
  yield takeLatest(
    updateAiDynamicProperties.type,
    updateAiDynamicPropertiesSaga,
  );
  yield takeLatest(processAiProperties.type, processAiPropertiesSaga);
  yield takeLatest(updateAiChanges.type, updateAiChangesSaga);
  yield takeLatest(removeChildInAiChanges.type, removeChildInAiChangesSaga);
  yield takeLatest(
    restoreDeletedChildInAiChanges.type,
    restoreDeletedChildInAiChangesSaga,
  );
  yield takeLatest(
    clearAiChanges.type as ActionPattern<any>,
    clearAiChangesSaga,
  );
  yield takeLatest(
    doSetupBeforeClarkRequest.type as ActionPattern<any>,
    doSetupBeforeClarkRequestSaga,
  );
}
