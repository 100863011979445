import { put } from "redux-saga/effects";
import { selectWidgets } from "legacy/actions/widgetActions";
import { setFocusedEntity } from "../slice";
import { ClarkFocusedEntityType } from "../types";

export function* setFocusedEntitySaga(
  action: ReturnType<typeof selectWidgets>,
) {
  const { widgetIds, selectingMultiple } = action.payload;

  if (selectingMultiple || widgetIds.length > 1) {
    return;
  }

  if (widgetIds.length === 1) {
    const widgetId = widgetIds[0];

    yield put(
      setFocusedEntity({
        entityId: widgetId,
        entityType: ClarkFocusedEntityType.WIDGET,
      }),
    );
  } else if (widgetIds.length === 0) {
    yield put(
      setFocusedEntity({
        entityId: undefined,
        entityType: undefined,
      }),
    );
  }
}
